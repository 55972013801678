import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';

import { AppModule } from './app/app.module';


platformBrowserDynamic().bootstrapModule(AppModule)
  .catch(err => console.error(err));

if (!Array.prototype.at) {
  Array.prototype.at = function(index) {
    const length = this.length;
    return index >= 0 ? this[index] : this[length + index];
  };
}
