import {RouterModule, Routes} from "@angular/router";
import {NgModule} from "@angular/core";
import {LoginComponent} from "./login/login.component";
import {BlankComponent} from "../layouts/blank/blank.component";
import {ForgotPasswordComponent} from "./forgot-password/forgot-password.component";
import {RestorePasswordComponent} from "./restore-password/restore-password.component";
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";
import {RegisterComponent} from "./register/register.component";

const routes: Routes = [
  {
    path: 'login',
    component: BlankComponent,
    children: [
      {path: '', component: LoginComponent},
      {path: 'reset', component: ForgotPasswordComponent},
      {path: 'register', component: RegisterComponent},
      {path: 'restore/:token', component: RestorePasswordComponent}
    ]
  },
  {
    path: 'privacy-policy',
    component: PrivacyPolicyComponent
  }
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
export class IndexRoutingModule {
}
