import {NgIf} from '@angular/common';
import {Component, Input, OnChanges, SimpleChanges} from '@angular/core';
import {CoreService} from "../../../../core/services/core.service";

@Component({
  selector: 'app-branding',
  standalone: true,
  imports: [NgIf],
  template: `
    <div class="branding">
      <a *ngIf="options.theme === 'light'">
        <img
          [src]="srcImage"
          class="align-middle m-2 img-logo"
          alt="logo"
            style="opacity: 1; transition: opacity 0.5s ease-in-out;"
        />
      </a>
      <a *ngIf="options.theme === 'dark'">
        <img
          [src]="srcImage"
          class="align-middle m-2 img-logo"
          alt="logo"
          style="opacity: 1; transition: opacity 0.5s ease-in-out;"
        />
      </a>
    </div>
  `,
})
export class BrandingComponent implements OnChanges {
  options = this.settings.getOptions();
  srcImage = './assets/images/logos/jesuits-logo.png';

  constructor(private settings: CoreService) {
    this.settings.notify.subscribe(() => {
      this.verifySize();
    })
  }

  verifySize() {
    if (this.options.sidenavCollapsed) {
      this.srcImage = './assets/images/logos/fondo blanco icono1024.png';
    } else {
      this.srcImage = './assets/images/logos/jesuits-logo.png';
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    this.verifySize();
  }
}
