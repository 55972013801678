import {Injectable} from "@angular/core";
import {HttpClient, HttpHeaders} from "@angular/common/http";
import {BehaviorSubject, map, of} from "rxjs";
import {EndpointService} from "./endpoint.service";
import {User} from "../models/user";

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  private urlLogin = "";
  private urlProtected = '';
  private urlRefresh = '';
  private urlReset = '';
  private urlRestore = '';
  private user: User | undefined;
  private tokenSubject = new BehaviorSubject<string | null>(null);
  private perms: string[] = [];

  constructor(private http: HttpClient, private endpointService: EndpointService) {
    this.endpointService.isReady().subscribe(ready => {
      if (ready) {
        this.urlLogin = this.endpointService.getKey('auth')
        this.urlProtected = this.endpointService.getKey('protected')
        this.urlRefresh = this.endpointService.getKey('refresh')
        this.urlReset = this.endpointService.getKey('reset')
        this.urlRestore = this.endpointService.getKey('restore')
      }
    });
    const token = localStorage.getItem('token')
    if (token) {
      this.setToken(token);
    }

  }

  getPerms() {
    return this.perms;
  }

  getToken(): string | null {
    return this.tokenSubject.value;
  }

  setToken(token: string): void {
    this.tokenSubject.next(token);
    localStorage.setItem('token', token);
  }

  auth(username: string | null, password: string | null) {
    return this.http.post(this.urlLogin, {
      username,
      password
    }, {
      headers: new HttpHeaders({
        'Content-Type': 'application/json',
        'Accept': '*/*'
      })
    }).pipe(map((resp: any) => {
      this.setToken(resp.token);
      this.user = undefined;
      localStorage.setItem('refreshToken', resp.refreshToken);
      return resp;
    }))
  }

  refresh() {
    return this.http.post(this.urlRefresh, {
      refreshToken: localStorage.getItem('refreshToken')
    }).pipe(map((resp: any) => {
      this.setToken(resp.token);
      localStorage.setItem('refreshToken', resp.refreshToken);
      return resp;
    }))
  }

  reset(username: string) {
    return this.http.get(this.urlReset + `/${username}`);
  }

  restore(token: string, password: string) {
    return this.http.post(this.urlRestore, {
      token: token,
      password: password
    });
  }

  getUserActive() {
    if (this.user) {
      return of(this.user);
    }

    return this.http.get<User>(this.urlProtected).pipe(map(user => {
      this.perms = [];
      user.authorities?.forEach(auth => {
        this.perms.push(auth.authority);
      });
      this.user = user;
      return user;
    }));
  }


}
