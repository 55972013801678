<div class="blank-layout-container justify-content-center align-items-center">
  <div
    class="position-relative row w-100 h-100 bg-gredient justify-content-center"
  >
    <div class="col-lg-4 d-flex align-items-center">
      <mat-card class="cardWithShadow boxed-auth">
        <mat-card-content class="p-32">
          <div class="text-center">
            <a
              [routerLink]="['/dashboards/dashboard1']"
              *ngIf="options.theme === 'light'"
            >
              <img
                src="./assets/images/logos/jesuits-logo.png"
                class="align-middle m-2"
                alt="logo"
              />
            </a>
            <a
              [routerLink]="['/dashboards/dashboard1']"
              *ngIf="options.theme === 'dark'"
            >
              <img
                src="./assets/images/logos/jesuits-logo.png"
                class="align-middle m-2"
                alt="logo"
              />
            </a>
          </div>
          <span class="f-s-14 d-block mat-body-1 m-t-8 text-center m-t-30"
          >{{'LOGIN.FORGOT_PASSWORD_TEXT' | translate}}</span
          >

          <form class="m-t-30" [formGroup]="form" >
            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
            >{{'LOGIN.USERNAME' | translate}}
            </mat-label
            >
            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input matInput type="email" formControlName="email"/>
              <mat-hint
                *ngIf="f['email'].touched && f['email'].invalid"
                class="m-b-16 error-msg"
              >
                <div
                  class="text-error"
                  *ngIf="f['email'].errors && f['email'].errors?.['required']"
                >
                  {{'LOGIN.ERRORS.USERNAME.REQUIRED' | translate}}
                </div>
              </mat-hint>
            </mat-form-field>

            <button mat-flat-button color="primary" class="w-100" [disabled]="!form.valid" (click)="submit()">
              {{'LOGIN.FORGOT_PASSWORD_BTN' | translate}}
            </button>
            <a
              [routerLink]="['/login']"
              mat-stroked-button
              color="primary"
              class="w-100 m-t-8"
            >
              {{'LOGIN.BACK_LOGIN' | translate}}
            </a>
            <!-- input -->
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
