<div class="blank-layout-container justify-content-center align-items-center">
  <div
    class="position-relative row w-100 h-100 bg-gredient justify-content-center"
  >
    <div class="col-lg-4 d-flex align-items-center">
      <mat-card class="cardWithShadow boxed-auth">
        <mat-card-content class="p-32">
          <div class="text-center">
            <a
              [routerLink]="['/dashboards/dashboard1']"
              *ngIf="options.theme === 'light'"
            >
              <img
                src="./assets/images/logos/jesuits-logo.png"
                class="align-middle m-2"
                alt="logo"
              />
            </a>
            <a
              [routerLink]="['/dashboards/dashboard1']"
              *ngIf="options.theme === 'dark'"
            >
              <img
                src="./assets/images/logos/jesuits-logo.png"
                class="align-middle m-2"
                alt="logo"
              />
            </a>
          </div>
          <span class="f-s-14 d-block mat-body-1 m-t-8 text-center m-t-30"
          >{{'LOGIN.RESTORE_PASSWORD_TEXT' | translate}}</span
          >

          <form class="m-t-30" [formGroup]="form" (ngSubmit)="submit()">
            <mat-label class="mat-subtitle-2 f-w-600 m-b-8 d-block"
            >{{'LOGIN.PASSWORD' | translate}}
            </mat-label
            >
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="{{'LOGIN.PASSWORD' | translate}}" type="password"
                     formControlName="password"/>
              <mat-icon matSuffix>
                <i-tabler name="lock" class="icon-20 d-flex"></i-tabler>
              </mat-icon>
              <mat-hint
                *ngIf="f['password'].touched && f['password'].invalid"
                class="m-b-16 error-msg"
              >
                <div
                  class="text-error"
                  *ngIf="f['password'].errors && f['password'].errors?.['required']"
                >
                  {{'LOGIN.ERRORS.PASSWORD.REQUIRED' | translate}}
                </div>
              </mat-hint>
            </mat-form-field>

            <!-- input -->
            <mat-label class="mat-subtitle-2 f-w-600 m-b-8 d-block"
            >{{'LOGIN.CONFIRM_PASSWORD' | translate}}
            </mat-label
            >
            <mat-form-field appearance="outline" class="w-100">
              <input matInput placeholder="{{'LOGIN.CONFIRM_PASSWORD' | translate}}" type="password"
                     formControlName="passwordConfirm"/>
              <mat-icon matSuffix>
                <i-tabler name="lock" class="icon-20 d-flex"></i-tabler>
              </mat-icon>
            </mat-form-field>

            <button mat-flat-button color="primary" class="w-100" [disabled]="!form.valid" (click)="submit()">
              {{'LOGIN.FORGOT_PASSWORD_BTN' | translate}}
            </button>
            <!-- input -->
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
