import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginComponent} from './login/login.component';
import {IndexRoutingModule} from "./index-routing.module";
import {CoreModule} from "../core/core.module";
import {MaterialModule} from "../material.module";
import {ReactiveFormsModule} from "@angular/forms";
import {TablerIconsModule} from "angular-tabler-icons";
import * as TablerIcons from 'angular-tabler-icons/icons';
import {PrivacyPolicyComponent} from "./privacy-policy/privacy-policy.component";

@NgModule({
  declarations: [PrivacyPolicyComponent],
  imports: [
    CommonModule,
    IndexRoutingModule,
    MaterialModule,
    TablerIconsModule.pick(TablerIcons),
    CoreModule,
    ReactiveFormsModule,
    LoginComponent
  ]
})
export class IndexModule {
}
