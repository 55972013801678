import {
  HttpClient,
  HttpErrorResponse,
  HttpEvent,
  HttpHandler,
  HttpInterceptor,
  HttpRequest
} from "@angular/common/http";
import {Injectable} from "@angular/core";
import {catchError, delay, Observable, retry, switchMap, throwError} from "rxjs";
import {Router} from "@angular/router";
import {EndpointService} from "./endpoint.service";
import {AuthService} from "./auth.service";

@Injectable({
  providedIn: 'root'
})
export class AuthInterceptor implements HttpInterceptor {

  constructor(private router: Router, private authService: AuthService) {
  }

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const token = this.authService.getToken();
    if (token && !req.url.includes('refresh')
      && !req.url.includes('login')
      && !req.url.includes('reset')
      && !req.url.includes('restore')
    ) {
      req = this.addToken(req, token);
    }

    return next.handle(req).pipe(catchError((err: HttpErrorResponse) => {
        if (err.status === 401) {

         /* this.authService.refresh().pipe(switchMap(resToken => {
            this.authService.setToken(resToken.token);
            req = this.addToken(req, resToken.token);
            return next.handle(req);
          })).subscribe();*/
          this.router.navigateByUrl('/login').then();
        }
        return throwError(() => err);
      })
    );
  }

  private addToken(request: HttpRequest<any>, token: string): HttpRequest<any> {
    return request.clone({
      setHeaders: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

}
