import {Component} from '@angular/core';
import {FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {Router, RouterModule} from '@angular/router';
import {NgIf} from '@angular/common';
import {CoreService} from "../../core/services/core.service";
import {MaterialModule} from "../../material.module";
import {TranslateModule} from "@ngx-translate/core";
import Swal from "sweetalert2";

@Component({
  selector: 'app-register',
  standalone: true,
  imports: [RouterModule, MaterialModule, FormsModule, ReactiveFormsModule, NgIf, TranslateModule],
  templateUrl: './register.component.html',
})
export class RegisterComponent {
  options = this.settings.getOptions();

  constructor(private settings: CoreService, private router: Router) {
  }

  form = new FormGroup({
    uname: new FormControl('', [Validators.required, Validators.minLength(6)]),
    email: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  get f() {
    return this.form.controls;
  }

  submit() {
    Swal.fire({
      icon: "success",
      text: "Te has registrado , una vez aprobado tendras acceso a la app"
    })
  }
}
