<div class="blank-layout-container justify-content-center align-items-center">
  <div
    class="position-relative row w-100 h-100 bg-gredient justify-content-center"
  >
    <div class="col-lg-4 d-flex align-items-center">
      <mat-card class="cardWithShadow boxed-auth">
        <mat-card-content class="p-32">
          <div class="text-center">
            <a
              [routerLink]="['/dashboards/dashboard1']"
              *ngIf="options.theme === 'light'"
            >
              <img
                src="./assets/images/logos/jesuits-logo.png"
                class="align-middle m-2"
                alt="logo"
              />
            </a>
            <a
              [routerLink]="['/dashboards/dashboard1']"
              *ngIf="options.theme === 'dark'"
            >
              <img
                src="./assets/images/logos/jesuits-logo.png"
                class="align-middle m-2"
                alt="logo"
              />
            </a>
          </div>

          <form class="m-t-30" [formGroup]="form" (ngSubmit)="submit()">
            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
            >{{'LOGIN.USERNAME' | translate}}
            </mat-label
            >
            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input matInput formControlName="uname"/>
              <mat-hint
                *ngIf="f['uname'].touched && f['uname'].invalid"
                class="m-b-16 error-msg"
              >
                <div
                  class="text-error"
                  *ngIf="f['uname'].errors && f['uname'].errors?.['required']"
                >
                  {{'LOGIN.ERRORS.USERNAME.REQUIRED' | translate}}
                </div>
                <div
                  class="text-error"
                  *ngIf="f['uname'].errors && f['uname'].errors?.['minlength']"
                >
                  {{'LOGIN.ERRORS.USERNAME.LENGTH' | translate}}
                </div>
              </mat-hint>
            </mat-form-field>
            <!-- password -->
            <mat-label class="mat-subtitle-2 f-s-14 f-w-600 m-b-12 d-block"
            >{{'LOGIN.PASSWORD' | translate}}
            </mat-label
            >
            <mat-form-field appearance="outline" class="w-100" color="primary">
              <input matInput type="password" formControlName="password"/>
              <mat-hint
                *ngIf="(f['password'].touched && f['password'].invalid) || credentialsErr"
                class="m-b-16 error-msg"
              >
                <div
                  class="text-error"
                  *ngIf="
                      f['password'].errors && f['password'].errors?.['required']
                    "
                >
                  {{'LOGIN.ERRORS.PASSWORD.REQUIRED' | translate}}
                </div>
                <div
                  class="text-error"
                  *ngIf="credentialsErr"
                >
                  {{'LOGIN.BAD_CREDENTIALS' | translate}}
                </div>
              </mat-hint>
            </mat-form-field>

            <div class="d-flex align-items-center m-b-12">
<!--              <mat-checkbox color="primary">{{'LOGIN.REMEMBER_ME' | translate}}</mat-checkbox>-->
              <a
                [routerLink]="['/login/register']"
                class="text-primary f-w-600 text-decoration-none m-r-auto f-s-14"
              > {{'LOGIN.REGISTER' | translate}}</a
              >
              <a
                [routerLink]="['/login/reset']"
                class="text-primary f-w-600 text-decoration-none m-l-auto f-s-14"
              > {{'LOGIN.FORGOT_PASSWORD' | translate}}</a
              >
            </div>
            <button mat-flat-button color="primary" class="w-100" [disabled]="!form.valid">
              {{'LOGIN.SING_IN' | translate}}
            </button>
          </form>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</div>
