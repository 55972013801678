import {Component} from '@angular/core';
import {FormGroup, FormControl, Validators, FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ActivatedRoute, Router, RouterModule} from '@angular/router';
import {NgIf} from '@angular/common';
import {MaterialModule} from "../../material.module";
import {CoreService} from "../../core/services/core.service";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../core/services/auth.service";
import Swal from 'sweetalert2'
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";
import {TablerIconsModule} from "angular-tabler-icons";

@Component({
  selector: 'app-restore-password',
  standalone: true,
  imports: [RouterModule, MaterialModule, FormsModule, ReactiveFormsModule, NgIf, TranslateModule, SweetAlert2Module, TablerIconsModule],
  templateUrl: './restore-password.component.html',
})
export class RestorePasswordComponent {
  options = this.settings.getOptions();
  token: string;

  constructor(private settings: CoreService, private router: Router, private authService: AuthService,
              private activatedRoute: ActivatedRoute) {
    this.token = this.activatedRoute.snapshot.params['token'];
  }

  form = new FormGroup({
    password: new FormControl('', [Validators.required]),
    passwordConfirm: new FormControl('', [Validators.required]),
  });

  get f() {
    return this.form.controls;
  }

  submit() {
    if (this.f.password.value && this.f.passwordConfirm.value) {
      this.authService.restore(this.token, this.f.password.value).subscribe(() => {
        Swal.fire({
          text: "Se ha restablecido su cuenta",
          icon: "success"
        }).then(() => {
          this.router.navigate(["/login"]);
        })
      });
    }

  }
}
