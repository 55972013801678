import {Directive, ElementRef, HostListener, Input, Renderer2} from "@angular/core";
import {NgControl} from "@angular/forms";
import {CurrencyPipe} from "@angular/common";

@Directive({
  selector: '[appOnly]'
})
export class OnlyDirective {
  @Input('appOnly') type: 'Letters' | 'Numbers' | 'Currency' | undefined;
  currencyPipe: CurrencyPipe;

  constructor(private el: ElementRef, private render: Renderer2, private control: NgControl) {
    this.currencyPipe = new CurrencyPipe('en-US')
  }

  @HostListener('input') oninput() {
    const abstractControl = this.control.control;
    let value = this.el.nativeElement.value;
    let r;
    let result;
    if (!this.type) {
      return;
    }
    if (this.type === 'Currency') {
      r = new RegExp('[,$A-z]', 'g');

      const numberFormat = String(value).replace(r, '');
      const arr = numberFormat.split('.');
      let money = this.currencyPipe.transform(numberFormat, '$', "symbol", '1.0-2');
      if (arr.length == 2 && !money?.includes('.')) {
        money += arr[1] == '' ? '.' : ''
      }
      this.render.setProperty(this.el.nativeElement, 'value', money);
    }
    if (this.type === 'Numbers') {
      r = new RegExp(/[^0-9]/g);
      const numberFormat = String(value).replace(r, '');
      this.render.setProperty(this.el.nativeElement, 'value', numberFormat);
    }
  }
}
