import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {OnlyDirective} from "./directives/only.directive";


@NgModule({
  declarations: [OnlyDirective],
  exports: [OnlyDirective],
  imports: [
    CommonModule
  ]
})
export class CoreModule {
}
