import {NgIf} from '@angular/common';
import {Component} from '@angular/core';
import {FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators} from "@angular/forms";
import {Router, RouterModule} from "@angular/router";
import {MaterialModule} from 'src/app/material.module';
import {CoreService} from "../../core/services/core.service";
import {TranslateModule} from "@ngx-translate/core";
import {AuthService} from "../../core/services/auth.service";

@Component({
  selector: 'app-login',
  standalone: true,
  imports: [RouterModule, MaterialModule, FormsModule, ReactiveFormsModule, NgIf, TranslateModule],
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent {

  options = this.settings.getOptions();
  credentialsErr = false;

  constructor(private settings: CoreService, private router: Router,
              private authService: AuthService) {
  }

  form = new FormGroup({
    uname: new FormControl('', [Validators.required]),
    password: new FormControl('', [Validators.required]),
  });

  get f() {
    return this.form.controls;
  }

  submit() {
    if (this.form.valid) {
      this.authService.auth(this.f.uname.value, this.f.password.value).subscribe({
        next: resp => {
          this.router.navigate(['/app/home']);
          this.credentialsErr = false;
        },
        error: err => {
          if (err) {
            this.credentialsErr = true;
          }
        }
      });
    }
  }
}
