import {APP_INITIALIZER, NgModule} from '@angular/core';
import {BrowserModule} from '@angular/platform-browser';

import {AppComponent} from './app.component';
import {IndexModule} from "./index/index.module";
import {RouterModule, RouterOutlet} from "@angular/router";
import {FormsModule, ReactiveFormsModule} from "@angular/forms";
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from "@angular/common/http";
import {BrowserAnimationsModule} from "@angular/platform-browser/animations";
import {AppRoutingModule} from "./app-routing.module";
import {MaterialModule} from './material.module';
import {TablerIconsModule} from 'angular-tabler-icons';
import * as TablerIcons from 'angular-tabler-icons/icons';

import {TranslateLoader, TranslateModule} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {BlankComponent} from "./layouts/blank/blank.component";
import {BrandingComponent} from "./layouts/full/vertical/sidebar/branding.component";
import {EndpointService} from "./core/services/endpoint.service";
import {AuthInterceptor} from "./core/services/auth.interceptor";
import {NgxPermissionsModule} from "ngx-permissions";
import {SweetAlert2Module} from "@sweetalert2/ngx-sweetalert2";

export function initialize(appConfig: EndpointService) {
  return () => appConfig.load();
}

export function HttpLoaderFactory(http: HttpClient): any {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    BlankComponent
  ],
  imports: [
    BrowserModule,
    IndexModule,
    RouterOutlet,
    BrowserAnimationsModule,
    HttpClientModule,
    RouterModule,
    ReactiveFormsModule,
    FormsModule,
    AppRoutingModule,
    MaterialModule,
    TablerIconsModule.pick(TablerIcons),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    BrandingComponent,
    NgxPermissionsModule.forRoot(),
    SweetAlert2Module.forRoot()
  ],
  providers: [{
    provide: APP_INITIALIZER,
    useFactory: initialize,
    deps: [EndpointService],
    multi: true
  },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AuthInterceptor,
      multi: true
    }],
  bootstrap: [AppComponent]
})
export class AppModule {
}
