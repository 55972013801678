import {Injectable} from "@angular/core";
import {HttpClient} from "@angular/common/http";
import {BehaviorSubject} from "rxjs";

@Injectable({
  providedIn: 'root',
})
export class EndpointService {
  endpoints: any[] = [];
  private $isReady: BehaviorSubject<boolean> = new BehaviorSubject(false);

  constructor(private http: HttpClient) {

  }

  load() {
    this.http.get<any[]>('/assets/config/endpoints.json').subscribe(resp => {
      this.endpoints = resp;
      const base = this.endpoints.find(s => s.key === 'base');
      this.endpoints.map(s => {
        s.endpoint = s.endpoint.replace('{{base}}', base.endpoint);
      });
      this.$isReady.next(true);
    });
  }

  isReady() {
    return this.$isReady
  }

  getKey(key: string) {
    try {
      return this.endpoints.find(s => s.key === key).endpoint;
    } catch (e) {
      return null;
    }
  }

}
